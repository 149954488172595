@import "main.css";
.navbar-nav > .dropdown {
display: none;
}

.navbar-brand { 
width: 9vw !important;
}

.informations-list .p-1 {
    padding: 0 !important;
}

